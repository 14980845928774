import { ProductLocation } from '@zupr/types/fo'
import { useMemo } from 'react'

export const useHasVariations = (productLocations) => {
    const productIds = useMemo(() => {
        if (!productLocations) return []
        return productLocations.reduce(
            (arr, { product }) => [...new Set(arr).add(product.id)],
            []
        )
    }, [productLocations])

    return productIds.length > 1
}

export const usePrices = (
    productLocations: ProductLocation[]
): ProductLocation['price'][] => {
    return useMemo(() => {
        if (!productLocations) return []
        const prices = productLocations
            .map(({ price }) => price)
            .filter((price) => !!price)
            .sort()
        return [...new Set(prices)] // array unique
    }, [productLocations])
}

export const useIsDiscounted = (
    productLocations: ProductLocation[]
): boolean => {
    const discountedPrices = useMemo(() => {
        if (!productLocations) return []
        const prices = productLocations
            .filter(
                ({ price, original_price, is_discount_price }) =>
                    is_discount_price && original_price !== price
            )
            .map(({ price }) => price)
            .sort()
        return [...new Set(prices)] // array unique
    }, [productLocations])

    return useMemo(() => {
        if (!productLocations) return false
        if (!discountedPrices.length) return false
        return productLocations.some(
            ({ is_discount_price }) => is_discount_price
        )
    }, [discountedPrices.length, productLocations])
}

export const useProductLocationsPrice = (
    productLocations: ProductLocation[]
) => {
    const prices = usePrices(productLocations)
    const isDiscounted = useIsDiscounted(productLocations)
    const isStarting = prices.length > 1
    const price = prices[0]

    // get discount
    // if only 1 pl and has discounted price
    // or all has the same discounted price
    const originalPrice =
        (isDiscounted &&
            !isStarting &&
            productLocations &&
            !!productLocations[0].original_price &&
            productLocations[0].original_price) ||
        undefined

    return useMemo(
        () => ({ price, isDiscounted, isStarting, originalPrice }),
        [price, isDiscounted, isStarting, originalPrice]
    )
}
