import { t } from '@zupr/i18n'
import { Variation } from '@zupr/types/fo'
import { ThemeTypeEnum } from '@zupr/types/graphql'
import { formatSubtitle } from '@zupr/utils/product'
import {
    useHasVariations,
    useProductLocationsPrice,
} from '@zupr/utils/product-locations'
import Link from 'next/link'

import Badge from '../../../../shared/components/badge'
import { ImgWithFallback } from '../../../../shared/components/image'
import Trans from '../../../../shared/components/trans'
import Discount from '../../../../shared/product/discount'
import Price from '../../../../shared/product/price'

interface ProductResultProps extends Variation {
    theme?: ThemeTypeEnum
    index: number
    href: string
}

const ProductResult = ({
    product_locations,
    product_locations_count,
    theme,
    index,
    href,
}: ProductResultProps) => {
    const product = product_locations[0].product
    const { images, title, brand } = product

    // gather data from multiple product_locations
    const hasVariations = useHasVariations(product_locations)
    const { price, isDiscounted, isStarting, originalPrice } =
        useProductLocationsPrice(product_locations)

    return (
        <li>
            <Link href={href}>
                <a>
                    <ImgWithFallback
                        image={images[0]}
                        alt={product.title}
                        eager={index < 6}
                    >
                        {(theme || isDiscounted) && (
                            <div className="badges">
                                {theme && (
                                    <Badge className={`badge-${theme}`}>
                                        {t(theme, 'themes')}
                                    </Badge>
                                )}
                                {isDiscounted && (
                                    <Badge danger>
                                        <Discount
                                            price={price}
                                            originalPrice={originalPrice}
                                        />
                                    </Badge>
                                )}
                            </div>
                        )}
                    </ImgWithFallback>

                    <div className="titles">
                        <h2>{title}</h2>
                        <h3>{formatSubtitle(product, brand)}</h3>
                        {hasVariations && (
                            <strong>
                                <Trans label="+ meer variaties" />
                            </strong>
                        )}
                    </div>

                    <div className="price-and-unit">
                        {originalPrice && (
                            <Price price={originalPrice} striked />
                        )}
                        {price && (
                            <Price
                                price={price}
                                isStarting={isStarting}
                                discounted={isDiscounted}
                            />
                        )}
                        <span className="product-locations-nearby">
                            <Trans
                                label="Te koop bij 1 winkel |||| Te koop bij %{smart_count} winkels"
                                values={{
                                    smart_count: product_locations_count,
                                }}
                            />
                        </span>
                    </div>
                </a>
            </Link>
        </li>
    )
}
export default ProductResult
